export default {
    name: 'global',
    store() {
        return {
            isMobileMenuVisible: false,
            isMinicartVisible: false,
            isPredictiveSearchVisible: false,
            isWindowScrolled: false,
            isMobile: false,
            isTablet: false,
            cart: null,
            headerVisible: true,
            init() {
                console.log('Groundowrk Global Store Initialized.')

                window.addEventListener('scroll', this.onWindowScrollHandler.bind(this));
                window.addEventListener('resize', this.checkDeviceType.bind(this));

                this.checkDeviceType();
                this.initLiquidAJaxCart();
            },
            get bodyClasses() {
                let classes = [];

                if (this.isMobileMenuVisible) {
                    classes.push('mobile-menu-visible');
                }

                if (this.deviceType === 'mobile') {
                    classes.push('device-mobile');
                } else if (this.deviceType === 'tablet') {
                    classes.push('device-tablet');
                } else {
                    classes.push('device-desktop');
                }

                return classes.join(' ') || '';
            },
            openMobileMenu() {
                this.isMobileMenuVisible = true;
            },
            closeMobileMenu() {
                this.isMobileMenuVisible = false;
            },
            toggleMobileMenu() {
                this.isMobileMenuVisible = !this.isMobileMenuVisible;
            },
            initLiquidAJaxCart() {
                document.addEventListener("liquid-ajax-cart:request-end", (event) => {
                    const { requestState, cart, previousCart, sections } = event.detail;

                    if (requestState.requestType === 'add') {
                        if (requestState.responseData?.ok) {
                            this.isMinicartVisible = true;
                        }
                    }

                    this.cart = cart;
                });
            },
            onWindowScrollHandler() {
                const isScrolled = window.scrollY > 0;

                this.isWindowScrolled = isScrolled;
                document.body.classList[isScrolled ? 'add' : 'remove']('scrolled');
            },
            checkDeviceType() {
                const width = window.innerWidth;

                if (width < 768) {
                    this.isMobile = true;
                    this.isTablet = true;
                } else {
                    this.isMobile = false;
                }

                if (width < 1024) {
                    this.isTablet = true;
                } else {
                    this.isTablet = false;
                }
            },
        }
    }
}
